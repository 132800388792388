import React from "react"
import Css from "./ContentHeader.module.css"
import { Link } from "gatsby"

const ContentHeader = props => {
  let data = props.content["content-header"]
  let bgColor = (props.bgColor)?props.bgColor:"#FFFFFF"

  let textsComps = []

  if (data["texts"]) {
    textsComps = data["texts"].map( (text, idx) => {
        return <div key={idx} className={Css.Text}>{text}</div>
    }) 
  }

  return (
    <div className={Css.ContentHeader} style={{backgroundColor:bgColor}} >
        {data["title1"] && 
         <div className={Css.TitleBig} >{data["title1"]}</div>
        }
        {data["texts"] && 
          <div className={Css.TextsContainer} >{textsComps}</div>
        }
        {data["action"] && 
          <div className={Css.ActionButtonContainer}>
            <Link to="/contact">
              <div className="action-button" >{data["action"]}</div>
            </Link>
          </div>
        }   
    </div>
  )
}

export default ContentHeader