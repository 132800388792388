import React from "react"
import Css from "./ServicesCardsSection.module.css"
import { Link } from "gatsby"

const ServicesCardsSection = props => {
  let items = []
  let data = props.content["cards-section"]
  if (data && data["cards"]) {
    items = data["cards"].map((item, idx) => {

      let jobs = item["jobs"].map(job => <div key={job.name} className={Css.JobItem}>{job.name}</div>)

      return <div key={idx} className={Css.CardItem} >
        <div className={Css.BlueContainer} >
          <div className={Css.PhraseContainer}>
            <div className={Css.Phrase}>{item["catch-phrase"]}</div>
            <Link className={Css.PhraseAction} to={`/${item["action-url"]}`} >
              {data.action} &gt;&gt;
           </Link>
          </div>
        </div>

        <div className={Css.Info} >
          <div className={Css.InfoTitle}>{item["title"]}</div>
          <div className={Css.InfoDesc}>{item["description"]}</div>
          <div className={Css.InfoJobs}>{jobs}</div>
        </div>

      </div>
    })
  }

  return (
    <div className={Css.ServicesCardsSection}  >
      {data["cards"] &&
        <div className={Css.CardsContainer} >{items}</div>
      }
    </div>
  )
}

export default ServicesCardsSection