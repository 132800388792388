import React from "react"

import Seo from "../components/Seo"
import SiteHeader from "../components/SiteHeader"
import GenericHero from "../components/GenericHero"
import ContentHeader from "../components/ContentHeader"
import TopImageContainer from "../components/TopImageContainer"
import LetsCoCreateSection from "../components/LetsCoCreateSection"
import SiteFooter from "../components/SiteFooter"

import ServicesCardsSection from "../components/Services/ServicesCardsSection"
import ServicesSpecialistSection from "../components/Services/ServicesSpecialistSection"

// import InsightsSection from "../components/InsightsSection"

import TopImage from "../images/top_service_bg.png"

import content from "../data/Content"

export default function Service() {
  let page = "services"
  let data = content(page)
  return (
    <div className="content">
      <Seo page={content(page)} />
      <TopImageContainer image={TopImage}>
        <SiteHeader page={page} content={content("header")} />
        <GenericHero content={data} />
      </TopImageContainer>
      <ContentHeader content={data} bgColor="#FFFFFF" />
      <ServicesCardsSection content={data} />
      <ServicesSpecialistSection content={data} />
      {/* <InsightsSection content={data} /> */}
      <LetsCoCreateSection content={content("lets-co-create-section")} />
      <SiteFooter content={content("footer")} />
    </div>
  )
}
