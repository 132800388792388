import React from "react"
import Css from "./GenericHero.module.css"

const GenericHero = props => {
  let data = props.content["hero"]
  return (
    <div className={Css.GenericHero}>
      <div className={Css.TitleContainer} >
        <div className={(data["customClass"] ? data["customClass"] : "") + " " + Css.TitleBig} >{data["title1"]}</div>
        {data["title2"] &&
          <div className={Css.TitleBig} >{data["title2"]}</div>
        }
        {data["subtitle"] &&
          <div className={Css.Subtitle}>{data["subtitle"]}</div>
        }
        {data["client-image"] &&
            <div className={Css.ClientContainer}>
              <div className={Css.ClientTitle}>Client</div>
              <img src={require('../images/' + data["client-image"])} alt="Brand" />
            </div>
        }
      </div>  
    </div>
  )
}

export default GenericHero