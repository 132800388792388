import React from "react"
import Css from "./ServicesSpecialistSection.module.css"

const ServicesSpecialistSection = props => {
  let data = props.content["specialist-section"]

  let textsComps = []
  let jobs = []
  let jobsIcons = []
  const styleBg = {

  }

  if (data["background"]) {
    styleBg.backgroundImage = ` url('${require('../../images/' + data["background"])}')`
  }

  if (data["texts"]) {
    textsComps = data["texts"].map((text, idx) => {
      return <div key={idx} className={Css.Text}>{text}</div>
    })
  }
  if (data["jobs"]) {

    jobs = data["jobs"].map((job, idx) => {
      let jobsItems = job.items.map(jobItem => {
        return <div key={jobItem} className={Css.JobItem}>{jobItem}</div>
      })
      return <div key={job.title + idx} className={Css.Job}>
        <div className={Css.JobTitle}>{job.title}</div>
        <div className={Css.JobItemsContainer}>{jobsItems}</div>
      </div>
    })

    jobsIcons = data["jobs-icons"].map((icon, idx) => {
      return <img className={Css.JobIcon} key={idx} src={require('../../images/' + icon)} alt={icon} />
    })
  }
  return (
    <div className={Css.ServicesSpecialistSection}  >
      {data["title1"] &&
        <div className={Css.TitleBig} >{data["title1"]}</div>
      }
      {data["texts"] &&
        <div className={Css.TextsContainer} >{textsComps}</div>
      }
      <div className={Css.JobsContainer} style={styleBg}>
        <div className={Css.JobsItemsContainer}>{jobs}</div>
        <div className={Css.IconsContainer}>{jobsIcons}</div>
      </div>

    </div>
  )
}

export default ServicesSpecialistSection