import React from "react"
import Css from "./LetsCoCreateSection.module.css"
import LetsImg from "../images/cocreate_img.png"
import { Link } from "gatsby"

const LetsCoCreateSection = props => {
  let data = props.content
  let bgColor = (props.bgColor)?props.bgColor:"#ffffff"

  return (
    <div className={Css.LetsCoCreateSection} style={{backgroundColor:bgColor}} >
      { data["title1"] &&
        <>
          <div className={Css.TitleBig} >{data["title1"]}</div>

          <div className={Css.ActionButtonContainer}>
            <Link to="/contact">
              <div className="action-button" >{data["action"]}</div>
            </Link>
          </div>

          <img className={Css.LetsCoCreateImg} src={LetsImg} alt="Connect people" />
        </>
      }
    </div>
  )
}

export default LetsCoCreateSection